// extracted by mini-css-extract-plugin
export var bottom = "FeatureCard-module--bottom--TqyMe";
export var card = "FeatureCard-module--card--Z+sNy";
export var container = "FeatureCard-module--container--W+HzK";
export var layout = "FeatureCard-module--layout--DqeW2";
export var limitWidthOnLargeScreens = "FeatureCard-module--limitWidthOnLargeScreens--uTcHi";
export var list = "FeatureCard-module--list--bO6jT";
export var listElement = "FeatureCard-module--listElement--hQ-++";
export var listElementText = "FeatureCard-module--listElementText--nqNy9";
export var right = "FeatureCard-module--right--oqLfs";
export var title = "FeatureCard-module--title--A3Qdg";
export var top = "FeatureCard-module--top--8Gb5R";