import { LINKS } from "@components/Link/Link";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { TypographyV2 } from "..";
import * as styles from "./IntegrateYourResearchV2.module.scss";

const IntegrateYourResearchV2 = () => {
	return (
		<div className={styles.container}>
			<span className={styles.content}>
				<TypographyV2
					variant="HEADING_2"
          mobileVariant="HEADING_3"
					weight="MEDIUM"
					color="base-white"
				>
					Integrate your research workflows and database
				</TypographyV2>
				<Link to={LINKS.DEMO.to}>
					<div className={styles.button}>
						<TypographyV2
							variant="BODY_TEXT_LARGE"
							weight="MEDIUM"
							color="brand-500"
							style={{ margin: 0 }}
						>
							Request Demo
						</TypographyV2>
						<svg
							width="28"
							height="28"
							viewBox="0 0 28 28"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M5.83333 13.9998H22.1667M22.1667 13.9998L14 5.83313M22.1667 13.9998L14 22.1665"
								stroke="#0728B6"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					</div>
				</Link>
			</span>
			<StaticImage
				src="./assets/img_1.png"
				alt="Integrate your research workflows and database"
			/>
		</div>
	);
};

export default IntegrateYourResearchV2;
