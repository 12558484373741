import TypographyV2 from "@components/TypographyV2/TypographyV2";
import classNames from "classnames";
import React, { HTMLProps } from "react";
import * as styles from "./FeatureCard.module.scss";

export type FeatureCardProps = {
	title: string;
	list: string[];
	image: JSX.Element;
	orientation?: "left" | "right";
	imageBottom?: boolean;
	sublist?: string[];
	className?: string;
} 

const FeatureCard = ({
	title,
	list,
	sublist,
	image,
	orientation = "left",
	imageBottom = false,
	className,
}: FeatureCardProps) => {

	let imageClassName = styles.image;
	let listClassName = styles.list;

	if (imageBottom) {
		imageClassName = classNames(imageClassName, styles.bottom); // add 'bottom' class if imageBottom is true
		listClassName = classNames(listClassName, styles.top); // add 'top' class if imageBottom is true
	}

	return (
		<div className={classNames(styles.container, className)}>
			<TypographyV2
				variant="HEADING_2"
				tabletVariant="HEADING_3"
				mobileVariant="HEADING_4"
				weight="MEDIUM"
				className={styles.title}
			>
				{title}
			</TypographyV2>
			<div className={classNames(styles.card, styles[orientation])}>
				<div className={listClassName}>
					{list.map((item, index) => {
						return (
							<div className={styles.listElement} key={index}>
								<svg
									width="32"
									height="32"
									viewBox="0 0 32 32"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									style={{
										minWidth: 24,
										minHeight: 24,
									}}
								>
									<path
										d="M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z"
										fill="#F5F7FF"
									/>
									<path
										d="M24 10L13 21L8 16"
										stroke="#5D7AF9"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
								<div className={styles.listElementText}>
									<TypographyV2
										variant="BODY_TEXT_EXTRA_LARGE"
										weight="MEDIUM"
										mobileVariant="BODY_TEXT_LARGE"
										style={{
											margin: 0,
										}}
									>
										{item}
									</TypographyV2>
									{sublist && sublist.length > 0 && sublist[index] && (
										<TypographyV2
											variant="BODY_TEXT_EXTRA_LARGE"
											weight="REGULAR"
											mobileVariant="BODY_TEXT_LARGE"
											style={{
												margin: 0,
											}}
										>
											{sublist[index]}
										</TypographyV2>
									)}
								</div>
							</div>
						);
					})}
				</div>
				<div className={imageClassName}>{image}</div>
			</div>
		</div>
	);
};

export default FeatureCard;

type FeatureCardLayoutProps = HTMLProps<HTMLDivElement>;

export const FeatureCardLayout = ({
	children,
	...props
}: FeatureCardLayoutProps) => {
	return (
		<div {...props} className={classNames(styles.layout, props.className)}>
			{children}
		</div>
	);
};
