import { LINKS, LinkType } from "@components/Link/Link";
import { Link } from "gatsby";
import React from "react";
import { TypographyV2 } from "..";
import * as styles from "./CardWithIcon.module.scss";

export type CardWithIconProps = {
	icon: JSX.Element;
	title: string;
	description: string;
	to: LinkType;
};

const CardWithIcon = ({ icon, title, description, to }: CardWithIconProps) => {
	return (
		<div className={styles.card}>
			<div className={styles.icon}>{icon}</div>
			<TypographyV2
				variant="BODY_TEXT_EXTRA_LARGE"
				mobileVariant="BODY_TEXT_LARGE"
				weight="MEDIUM"
				style={{ margin: 0 }}
			>
				{title}
			</TypographyV2>
			<TypographyV2
				variant="BODY_TEXT_EXTRA_LARGE"
				mobileVariant="BODY_TEXT_LARGE"
				weight="REGULAR"
				style={{ margin: 0, marginBottom: 16 }}
			>
				{description}
			</TypographyV2>
			<Link to={LINKS[to].to} style={{ marginTop: "auto" }}>
				<div className={styles.button}>
					<TypographyV2
						variant="BODY_TEXT_MEDIUM"
						weight="MEDIUM"
						color="brand-600"
					>
						Learn more
					</TypographyV2>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M5 11.9998H19M19 11.9998L12 4.99976M19 11.9998L12 18.9998"
							stroke="#051D85"
							stroke-width="1.5"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				</div>
			</Link>
		</div>
	);
};

export default CardWithIcon;
