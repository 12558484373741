import {
	RequestDemoButtonV2,
	SignUpButtonV2,
} from "@components/Button_new/Button";
import { TypographyV2 } from "@components/index";
import React, { HTMLProps } from "react";
import * as styles from "./Hero.module.scss";
import Margins from "@components/Margins/Margins";
import classNames from "classnames";

type TextProps = {
	title: string;
	description: string;
	tabletTextAlignment?: "left" | "center";
	heroTextContainerSize?: "default" | "small";
};

type HeroProps = TextProps & {
	image: any;
	tabletTextAlignment?: "left" | "center";
	heroTextContainerSize?: "default" | "small";
} & HTMLProps<HTMLDivElement>;

const TextButtonsContainer = (props: TextProps) => {
	return (
		<div
			className={classNames(styles.textButtonsContainer, {
				[styles.tabletAlignmentCenter]:
					props.tabletTextAlignment === "center",
				[styles.textLinesAlignment]: props.heroTextContainerSize === "small",
			})}
		>
			<TypographyV2
				variant="HEADING_1"
				tabletVariant="HEADING_2"
				mobileVariant="HEADING_3"
				weight="BOLD"
				className={styles.headerText}
			>
				{props.title}
			</TypographyV2>
			<TypographyV2
				variant="BODY_TEXT_EXTRA_LARGE"
				mobileVariant="BODY_TEXT_LARGE"
				weight="REGULAR"
				color="brand-subtle"
				className={styles.bodyText}
			>
				{props.description}
			</TypographyV2>
			<div className={styles.buttons}>
				<RequestDemoButtonV2 />
				<SignUpButtonV2 />
			</div>
		</div>
	);
};

export const Hero = (props: HeroProps) => {
	return (
		<div className={classNames(styles.hero, props.className)}>
			<TextButtonsContainer
				title={props.title}
				description={props.description}
				tabletTextAlignment={props.tabletTextAlignment}
				heroTextContainerSize={props.heroTextContainerSize}
			/>
			<div className={styles.heroImage}>{props.image}</div>
		</div>
	);
};
