import React from "react";
import { PageLayout } from "@components";
import LabConsumablesCards from "pages/products/lab-consumables/_components/LabConsumablesCards";
import IntegrateYourResearchV2 from "@components/IntegrateYourResearchV2/IntegrateYourResearchV2";
import * as styles from "./experiments.module.scss";
import Margins from "@components/Margins/Margins";
import { ExperimentsHero } from "./_components/_ExperimentsHero/_ExperimentsHero";
import FeatureCard, { FeatureCardLayout, FeatureCardProps } from "@components/FeatureCard/FeatureCard";
import { StaticImage } from "gatsby-plugin-image";

const cards: FeatureCardProps[] = [
	{
		title: "Structured experiment management for better productivity",
		image: (
			<StaticImage
				src={"./_assets/notebook-file.png"}
				alt="Structured experiment management for better productivity"
			/>
		),
		list: [
			"Simplify your workflow with an intuitive system for files and folders, allowing you to easily track your experiments",
			"Our multi-tab view feature lets you input data and monitor experiments concurrently in a single browser window",
			"Get more done by tackling multiple experiments in real-time – all within a unified, clutter-free interface",
		],
	},
	{
		title: "Dynamic document creation and collaboration",
		image: (
			<StaticImage
				src={"./_assets/live-editing.png"}
				alt="Dynamic document creation and collaboration"
			/>
		),
		list: [
			"Live document editing within the main text editor saves you time and gives you instant updates",
			"Collaborate seamlessly with team members in one place, via real-time edits, comments, and feedback; no more confusion over different “versions”",
			"Draft, revise, and finalize research documents efficiently while retaining full document version history",
		],
		orientation: "right",
	},
	{
		title: "Integrated inventory for robust documentation",
		image: (
			<StaticImage
				src={"./_assets/experiment-materials.png"}
				alt="Integrated inventory for robust documentation"
			/>
		),
		list: [
			"Simplify your experiment setup by linking your inventory directly to your electronic lab notebook",
			"Automatically generate comprehensive materials lists for each experiment, so that you can accurately track your data and minimize any manual entry errors",
			"Keep your experiments accurate with real-time inventory updates, so your ELN always has the latest stock information",
		],
	},
];

export default function Experiments() {
	return (
		<PageLayout
			seoOptions={{ type: "PREDEFINED", pageName: "experiments" }}
		>
			<Margins>
				<ExperimentsHero />
				<FeatureCardLayout className={styles.layout}>
					{cards.map((card, index) => {
						return <FeatureCard {...card} key={index} />;
					})}
				</FeatureCardLayout>
				<IntegrateYourResearchV2 />
				<LabConsumablesCards />
			</Margins>
		</PageLayout>
	);
}
